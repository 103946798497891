import React, {
  useCallback,
  createContext,
  useState,
  useMemo,
  useRef,
  ReactElement
} from 'react';
import { fabric } from 'fabric';
import 'src/fabric-overrides';

interface IFabricContext {
  canvas: fabric.Canvas | undefined;
  mouseOverObjectRef: { current: boolean };
  isMouseDownRef: { current: boolean };
  objectToCreateRef: { current: fabric.Object | undefined };
  activeObjectRef: { current: fabric.Object | undefined };
  initCanvas: (canvas: HTMLCanvasElement | null) => void;
  getObjects: () => fabric.Object[];
  clearCanvas: () => void;
}

export const FabricContext = createContext<IFabricContext>(undefined as any);

type FabricContextProviderProps = {
  children: ReactElement | ReactElement[];
};

export function FabricContextProvider({
  children
}: FabricContextProviderProps) {
  const [canvas, setCanvas] = useState<fabric.Canvas>();
  const mouseOverObjectRef = useRef<boolean>(false);
  const isMouseDownRef = useRef<boolean>(false);
  const objectToCreateRef = useRef<fabric.Object>();
  const activeObjectRef = useRef<fabric.Object>();

  mouseOverObjectRef.current = false;
  isMouseDownRef.current = false;
  objectToCreateRef.current = undefined;
  activeObjectRef.current = undefined;

  const initCanvas = useCallback((template) => {
    const canvas = new fabric.Canvas(template, {
      selectionColor: 'transparent',
      selectionLineWidth: 0,
      centeredScaling: true,
      targetFindTolerance: 5,
      preserveObjectStacking: true,
      perPixelTargetFind: true,
      enableRetinaScaling: false,
      imageSmoothingEnabled: false,
      width: 300,
      height: 300,
      allowTouchScrolling: true
    });
    canvas.renderAll();
    setCanvas(canvas);
  }, []);

  const getObjects = useCallback(() => {
    if (!canvas) return [];
    return canvas.getObjects();
  }, [canvas]);

  const clearCanvas = useCallback(() => {
    if (!canvas) return;
    canvas.clear();
    canvas.requestRenderAll();
  }, [canvas]);

  const providerValue = useMemo(() => {
    return {
      canvas,
      initCanvas,
      getObjects,
      clearCanvas,
      mouseOverObjectRef,
      isMouseDownRef,
      activeObjectRef,
      objectToCreateRef
    };
  }, [canvas, initCanvas, getObjects, clearCanvas]);

  return (
    <FabricContext.Provider value={providerValue}>
      {children}
    </FabricContext.Provider>
  );
}

export default FabricContextProvider;
