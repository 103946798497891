import { fabric } from 'fabric';

fabric.Object.prototype.borderColor = '#65BBFB';
fabric.Object.prototype.cornerColor = 'white';
fabric.Object.prototype.cornerStrokeColor = '#65BBFB';
fabric.Object.prototype.transparentCorners = false;

// Allow touch scrolling
// Backport of https://github.com/fabricjs/fabric.js/pull/10078
// See: https://github.com/fabricjs/fabric.js/issues/5903 for more context
(() => {
  Object.assign(fabric.Canvas.prototype, {
    _onTouchStart(this: fabric.Canvas, e: TouchEvent) {
      // we will prevent scrolling if allowTouchScrolling is not enabled and
      let shouldPreventScrolling = !this.allowTouchScrolling;
      const currentActiveObject = this._activeObject;
      if ((this as any).mainTouchId === null) {
        (this as any).mainTouchId = (this as any).getPointerId(e);
      }
      (this as any).__onMouseDown(e);
      // after executing fabric logic for mouse down let's see
      // if we didn't change target or if we are drawing
      // we want to prevent scrolling anyway
      if (
        (this as any).isShapeDrawingMode ||
        (this as any).isDraggingMode ||
        this.isDrawingMode ||
        (currentActiveObject && (this as any)._target === currentActiveObject)
      ) {
        shouldPreventScrolling = true;
      }
      // prevent default, will block scrolling from start
      if (shouldPreventScrolling) {
        e.preventDefault();
      }
      (this as any)._resetTransformEventData();
      const canvasElement = (this as any).upperCanvasEl;
      const eventTypePrefix = (this as any)._getEventPrefix();
      const doc = canvasElement.ownerDocument || null;
      fabric.util.addListener(
        doc,
        `touchend`,
        (this as any)._onTouchEnd as EventListener
      );
      // if we scroll don't register the touch move event
      if (shouldPreventScrolling) {
        fabric.util.addListener(
          doc,
          'touchmove',
          (this as any)._onMouseMove as EventListener
        );
      }
      // Unbind mousedown to prevent double triggers from touch devices
      fabric.util.removeListener(
        canvasElement,
        `${eventTypePrefix}down`,
        (this as any)._onMouseDown as EventListener
      );
    }
  });
})();
